<template>
  <fragment> 
    <Area ref="child" v-if="role.isVolvo"/>
    <areaDefault ref="child" v-else />
  </fragment> 
</template>

<script>
import Vue from 'vue'
import {getRole} from '@/utils/getRole';
import Area from './area.vue';
import areaDefault from './areaDefault.vue';


export default {
  data() {
      return {
        role: getRole(),
      }
  },
  components:{
    Area,
    areaDefault,
  },
  methods: {
    getDataBySearch(q) {
      this.$refs.child && this.$refs.child.getDataBySearch(q);
    },
  },
  created() {
  },
  mounted () {
  },
}
</script>

<style lang="less">

</style>


